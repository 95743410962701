import {
  Button,
  EASINGS,
  Heading,
  ScaleFade,
  Text,
  VStack,
} from "@chakra-ui/react"
import { Link as GatsbyLink } from "gatsby"
import React from "react"
import { Layout } from "../components/layout/Layout"
import { LayoutBody } from "../components/layout/LayoutBody"
import { BareNav } from "../components/layout/Nav"
import { PageTitle } from "../components/typing-test/PageTitle"

export default function () {
  return (
    <Layout>
      <PageTitle>Page Not Found</PageTitle>
      <BareNav />
      <LayoutBody size="md">
        <ScaleFade
          in
          variants={{
            exit: {
              opacity: 0,
              rotate: "20deg",
              scale: 0.5,
              transition: {
                duration: 0.1,
                ease: EASINGS.easeOut,
              },
            },
            enter: {
              opacity: 1,
              scale: 1,
              rotate: "0deg",
              transition: { type: "spring", duration: 0.7, bounce: 0.5 },
            },
          }}
        >
          <VStack spacing={4} textAlign="center">
            {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
            <Text fontSize="5xl" lineHeight="1">
              🙈
            </Text>
            <Heading>Page Not Found</Heading>
            <Text opacity={0.6}>
              We couldn't find the page you were looking for. Please go back to
              the home page.
            </Text>
            <Button as={GatsbyLink} to="/" size="lg">
              Back to Home
            </Button>
          </VStack>
        </ScaleFade>
      </LayoutBody>
    </Layout>
  )
}
